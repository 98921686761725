import Survey from '@/survey/models/Survey';
import { BaseService } from '../generic/services/baseService';
import EnvService from '../generic/services/EnvService';
import Answer from '@/survey/models/Answer';
import LogHelper from '@/generic/helpers/LogHelper';
import IVerificationStatus from '@/survey/models/IVerificationStatus';
import { FinishSurveyStatus } from '@/survey/models/FinishSurveyStatus';
import SurveyListResponse, { SurveyListLanguage } from '@/survey/models/SurveyListResponse';
import Question from '@/survey/models/Question';
import IPostAnswer from '@/survey/models/IPostAnswer';
import DemographicQuestion from '@/report/models/IDemographicQuestion';
import { Country } from './models/Country';
import countries from 'country-list';
import to from 'await-to-js';
import { appInsightsHub } from '@/store/appInsights';

export class SurveyService extends BaseService {
    private endpoint = `${EnvService.getEnvironment().apiEndpoint}`;

    constructor(auth: boolean = true) {
        super(auth);
    }

    public async getSurveys(prefLanguageCode: string): Promise<SurveyListResponse> {
        const url = `${this.endpoint}/surveys?language=${prefLanguageCode}`;
        const response = await this.get(url);
        return new SurveyListResponse(response.data);
    }

    public async getSurvey(surveyId: number): Promise<Survey> {
        const url = `${this.endpoint}/surveys/${surveyId}`;
        const response = await this.get(url);
        const survey = new Survey(response.data);
        return this.mapSurveyId(survey, surveyId);
    }

    public async startSurvey(surveyId: number, context: string = null): Promise<Survey> {
        const url = `${this.endpoint}/surveys/${surveyId}/start`;
        const response = await this.post(url, { context });
        const survey = new Survey(response.data);
        return this.mapSurveyId(survey, surveyId);
    }

    public async getAnonymousSurvey(projectId: number, surveyKey: string): Promise<SurveyRedirectLink> {
        const url = `${this.endpoint}/anonymous-surveys?project_id=${projectId}&survey_key=${surveyKey}`;
        const [err, response] = await to(this.get(url));

        if (err) {
            LogHelper.error('Failed to fetch anonymous survey');
            return null;
        }

        return new SurveyRedirectLink(response.data);
    }

    public async getDemographicQuestions(surveyId: number): Promise<Question[]> {
        try {
            const response = await this.get(this.endpoint + '/surveys/' + surveyId + '/demographic-questions');
            return response.data as Question[];
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    public async getCountries(): Promise<Country[]> {
        return countries.getNames().map((c) => new Country({ name: c }));
    }

    public async getAnswerOptions(questionId: number, surveyId: number): Promise<Answer[]> {
        if (!surveyId) {
            surveyId = null;
        }

        try {
            const response = await this.get(`${this.endpoint}/questions/${questionId}/answers?surveyId=${surveyId}`);
            return response.data as Answer[];
        } catch (e) {
            return [];
        }
    }

    public async getDemographicAnswerOptions(questionId: number, surveyId: number): Promise<Answer[]> {
        if (!surveyId) {
            surveyId = null;
        }

        try {
            const response = await this.get(`${this.endpoint}/questions/${questionId}/demographic-answers?surveyId=${surveyId}`);
            return response.data as Answer[];
        } catch (e) {
            return [];
        }
    }

    public async postAnswer(answerModel: IPostAnswer): Promise<void> {
        appInsightsHub.value.instance?.trackEvent(
            { name: `post_answer: ${answerModel.surveyId}` },
            { surveyId: answerModel.surveyId, questionId: answerModel.questionId },
        );
        await this.post(this.endpoint + '/questions', answerModel);
    }

    public async verify(surveyId: number): Promise<boolean> {
        try {
            const response = await this.get(`${this.endpoint}/surveys/${surveyId}/verify`);
            const status: IVerificationStatus = response.data;
            return status.IsCompleted;
        } catch (e) {
            LogHelper.error('Could not verify survey');
            return false;
        }
    }

    public async complete(surveyId: number, languageCode: string): Promise<FinishSurveyStatus> {
        let status = FinishSurveyStatus.NotCompleted;
        appInsightsHub.value.instance?.trackEvent({ name: `finish_survey: ${surveyId}` }, { surveyId });
        try {
            await this.post(`${this.endpoint}/surveys/${surveyId}/finish`, { languageCode });
            status = FinishSurveyStatus.Completed;
        } catch (e) {
            status = FinishSurveyStatus.NotVerified;
            appInsightsHub.value.instance?.trackException({ exception: e });
        }
        return status;
    }

    public async getLanguages(projectId: number | string, reportAlias: string): Promise<SurveyListLanguage[]> {
        try {
            const response = await this.get(`${this.endpoint}/projects/${projectId}/languages?report_alias=${reportAlias}`);
            return response.data.map((x) => new SurveyListLanguage(x));
        } catch (e) {
            LogHelper.error('Failed to fetch languages');
            return [];
        }
    }

    public async getDemographicsOfProject(projectId: number, token: string): Promise<DemographicQuestion[]> {
        const url = `${this.endpoint}/projects/${projectId}/demographics`;

        try {
            const response = await this.get(url, null, {
                headers: {
                    Authorization: `TeamToken ${token}`,
                },
            });
            return response.data as DemographicQuestion[];
        } catch (e) {
            LogHelper.error('Failed to fetch demographics data.');
            return null;
        }
    }

    private mapSurveyId(survey: Survey, surveyId: number) {
        // Map survey id because we used it when posting answers
        survey.surveyId = surveyId;
        survey.questions = survey.questions.map((q: Question) => {
            return new Question({
                ...q,
                surveyId: surveyId,
            });
        });

        survey.questions = survey.questions.sort((a, b) => {
            if (a.section !== b.section) {
                return a.section - b.section;
            }
            return a.questionNumber - b.questionNumber;
        });
        return survey;
    }
}

export class SurveyRedirectLink {
    projectName: string = '';
    surveyLink: string = '';
    projectId: number = null;

    constructor(data: Partial<SurveyRedirectLink>) {
        if (data) {
            this.projectName = data.projectName;
            this.surveyLink = data.surveyLink;
        }
    }
}
