<template>
    <page>
        <!-- DON'T ASK WHY-->
        <p style="display:none;">Ideeën</p>

        <survey-translate style="font-size: x-large; text-align: center;" tag="div" base="reportsWeb" name="PERSONAL_REPORT_TITLE" />
        <survey-translate
            class="pit-participant-title"
            tag="div"
            base="reportsWeb"
            name="PARTICIPANT_NAME"
            :args="{ firstName: reportData.firstName, lastName: reportData.lastName }"
        />
        <survey-translate class="pit-title" tag="div" base="reportsWeb" name="GENERAL_INFO_TITLE" />
        <survey-translate class="pit-explainer-title" tag="div" base="reportsWeb" name="GENERAL_INFO_SUBTITLE" />
        <survey-translate
            base="reportsWeb"
            name="GENERAL_INFO_TEXT"
            :args="{ firstName: reportData.firstName, lastName: reportData.lastName, dateCompleted: date }"
            raw
        />
        <survey-translate class="pit-explainer-title" tag="div" base="reportsWeb" name="SCHEMATIC_TITLE" raw />
        <survey-translate tag="div" base="reportsWeb" name="SCHEMATIC_TEXT" raw />
        <pit-wheel-wrapper :reportData="reportData" showStrokes />
    </page>
    <page>
        <survey-translate class="pit-explainer-title" tag="div" base="reportsWeb" name="LEGENDA_TITLE" raw />
        <survey-translate tag="div" base="reportsWeb" name="LEGENDA_SUB_TITLE" raw />
        <div class="pit-explainer-wrapper">
            <survey-translate
                tag="div"
                class="pit-header"
                :style="{ 'background-color': colors.leiderschapColor }"
                name="LEIDERSCHAP_TITLE"
                base="reportsWeb"
            />
            <survey-translate class="subconstruct-title" tag="div" name="LEIDERSCHAP_SUBCONSTRUCT_A" base="reportsWeb" />
            <survey-translate tag="p" name="LEIDERSCHAP_SUBCONSTRUCT_A_TEXT" base="reportsWeb" />

            <survey-translate class="subconstruct-title" tag="div" name="LEIDERSCHAP_SUBCONSTRUCT_B" base="reportsWeb" />
            <survey-translate tag="p" name="LEIDERSCHAP_SUBCONSTRUCT_B_TEXT" base="reportsWeb" />
        </div>
        <div class="pit-explainer-wrapper">
            <survey-translate
                tag="div"
                class="pit-header"
                :style="{ 'background-color': colors.conflicthanteringColor }"
                name="CONFLICTHANTERING_TITLE"
                base="reportsWeb"
            />
            <survey-translate class="subconstruct-title" tag="div" name="CONFLICTHANTERING_SUBCONSTRUCT_A" base="reportsWeb" />
            <survey-translate tag="p" name="CONFLICTHANTERING_SUBCONSTRUCT_A_TEXT" base="reportsWeb" />

            <survey-translate class="subconstruct-title" tag="div" name="CONFLICTHANTERING_SUBCONSTRUCT_B" base="reportsWeb" />
            <survey-translate tag="p" name="CONFLICTHANTERING_SUBCONSTRUCT_B_TEXT" base="reportsWeb" />
        </div>
        <div class="pit-explainer-wrapper">
            <survey-translate
                tag="div"
                class="pit-header"
                :style="{ 'background-color': colors.samenwerkingColor }"
                name="SAMENWERKING_TITLE"
                base="reportsWeb"
            />
            <survey-translate class="subconstruct-title" tag="div" name="SAMENWERKING_SUBCONSTRUCT_A" base="reportsWeb" />
            <survey-translate tag="p" name="SAMENWERKING_SUBCONSTRUCT_A_TEXT" base="reportsWeb" />

            <survey-translate class="subconstruct-title" tag="div" name="SAMENWERKING_SUBCONSTRUCT_B" base="reportsWeb" />
            <survey-translate tag="p" name="SAMENWERKING_SUBCONSTRUCT_B_TEXT" base="reportsWeb" />
        </div>
        <div class="pit-explainer-wrapper">
            <survey-translate
                tag="div"
                class="pit-header"
                :style="{ 'background-color': colors.flexibiliteitColor }"
                name="FLEXIBILITEIT_TITLE"
                base="reportsWeb"
            />
            <survey-translate class="subconstruct-title" tag="div" name="FLEXIBILITEIT_SUBCONSTRUCT_A" base="reportsWeb" />
            <survey-translate tag="p" name="FELXIBILITEIT_SUBCONSTRUCT_A_TEXT" base="reportsWeb" />

            <survey-translate class="subconstruct-title" tag="div" name="FLEXIBILITEIT_SUBCONSTRUCT_B" base="reportsWeb" />
            <survey-translate tag="p" name="FLEXIBILITEIT_SUBCONSTRUCT_B_TEXT" base="reportsWeb" />
        </div>
        <div class="pit-explainer-wrapper">
            <survey-translate tag="div" class="pit-header" :style="{ 'background-color': colors.nieuwColor }" name="NIEUW_TITLE" base="reportsWeb" />
            <survey-translate class="subconstruct-title" tag="div" name="NIEUW_SUBCONSTRUCT_A" base="reportsWeb" />
            <survey-translate tag="p" name="NIEUW_SUBCONSTRUCT_A_TEXT" base="reportsWeb" />

            <survey-translate class="subconstruct-title" tag="div" name="NIEUW_SUBCONSTRUCT_B" base="reportsWeb" />
            <survey-translate tag="p" name="NIEUW_SUBCONSTRUCT_B_TEXT" base="reportsWeb" />
        </div>
        <div class="pit-explainer-wrapper">
            <survey-translate
                tag="div"
                class="pit-header"
                :style="{ 'background-color': colors.prestatiegerichtheidColor }"
                name="PRESTATIEGERICHTHEID_TITLE"
                base="reportsWeb"
            />
            <survey-translate class="subconstruct-title" tag="div" name="PRESTATIEGERICHTHEID_SUBCONSTRUCT_A" base="reportsWeb" />
            <survey-translate tag="p" name="PRESTATIEGERICHTHEID_SUBCONSTRUCT_A_TEXT" base="reportsWeb" />

            <survey-translate class="subconstruct-title" tag="div" name="PRESTATIEGERICHTHEID_SUBCONSTRUCT_B" base="reportsWeb" />
            <survey-translate tag="p" name="PRESTATIEGERICHTHEID_SUBCONSTRUCT_B_TEXT" base="reportsWeb" />
        </div>
        <div class="pit-explainer-wrapper">
            <survey-translate
                tag="div"
                class="pit-header"
                :style="{ 'background-color': colors.zorgvuldigheidColor }"
                name="ZORGVULDIGHEID_TITLE"
                base="reportsWeb"
            />
            <survey-translate class="subconstruct-title" tag="div" name="ZORGVULDIGHEID_SUBCONSTRUCT_A" base="reportsWeb" />
            <survey-translate tag="p" name="ZORGVULDIGHEID_SUBCONSTRUCT_A_TEXT" base="reportsWeb" />

            <survey-translate class="subconstruct-title" tag="div" name="ZORGVULDIGHEID_SUBCONSTRUCT_B" base="reportsWeb" />
            <survey-translate tag="p" name="ZORGVULDIGHEID_SUBCONSTRUCT_B_TEXT" base="reportsWeb" />
        </div>
    </page>
    <page>
        <survey-translate class="pit-explainer-title" tag="div" base="reportsWeb" name="INDEPTH_EXPLAINER_TITLE" raw />
        <pit-explainer
            translateKey="CONFLICTHANTERING"
            :color="colors.conflicthanteringColor"
            :scoreA="reportData.conflicthanteringAssertiviteit"
            :scoreB="reportData.conflicthanteringControle"
            :explainer="translateValues.conflicthanteringAssertiviteit + '_' + translateValues.conflicthanteringControle"
            :participantName="reportData.firstName"
        />
        <pit-explainer
            translateKey="FLEXIBILITEIT"
            :color="colors.flexibiliteitColor"
            :scoreA="reportData.flexibiliteitVerandering"
            :scoreB="reportData.flexibiliteitAfmaken"
            :explainer="translateValues.flexibiliteitVerandering + '_' + translateValues.flexibiliteitAfmaken"
            :participantName="reportData.firstName"
        />
        <pit-explainer
            translateKey="LEIDERSCHAP"
            :color="colors.leiderschapColor"
            :scoreA="reportData.leiderschapVerlangen"
            :scoreB="reportData.leiderschapGeloof"
            :explainer="translateValues.leiderschapVerlangen + '_' + translateValues.leiderschapGeloof"
            :participantName="reportData.firstName"
        />
    </page>
    <page>
        <pit-explainer
            translateKey="NIEUW"
            :color="colors.nieuwColor"
            :scoreA="reportData.nieuwKwetsbaarheid"
            :scoreB="reportData.nieuwImpulsiviteit"
            :explainer="translateValues.nieuwKwetsbaarheid + '_' + translateValues.nieuwImpulsiviteit"
            :participantName="reportData.firstName"
        />
        <pit-explainer
            translateKey="PRESTATIEGERICHTHEID"
            :color="colors.prestatiegerichtheidColor"
            :scoreA="reportData.prestatiegerichtheidStreven"
            :scoreB="reportData.prestatiegerichtheidHardwerken"
            :explainer="translateValues.prestatiegerichtheidStreven + '_' + translateValues.prestatiegerichtheidHardwerken"
            :participantName="reportData.firstName"
        />
        <pit-explainer
            translateKey="SAMENWERKING"
            :color="colors.samenwerkingColor"
            :scoreA="reportData.samenwerkingOnderdeel"
            :scoreB="reportData.samenwerkingSfeer"
            :explainer="translateValues.samenwerkingOnderdeel + '_' + translateValues.samenwerkingSfeer"
            :participantName="reportData.firstName"
        />
    </page>
    <page>
        <pit-explainer
            translateKey="ZORGVULDIGHEID"
            :color="colors.zorgvuldigheidColor"
            :scoreA="reportData.zorgvuldigheidStructuur"
            :scoreB="reportData.zorgvuldigheidDetails"
            :explainer="translateValues.zorgvuldigheidStructuur + '_' + translateValues.zorgvuldigheidDetails"
            :participantName="reportData.firstName"
        />
    </page>
</template>
<script src="./PitPersonal.ts"></script>
<style lang="scss">
.pit-explainer-title {
    font-size: medium;
    font-weight: bold;
}

.pit-title {
    font-size: large;
}

.pit-participant-title {
    font-size: x-large;
    font-weight: bold;
    color: #da1c66;
    text-align: center;
}

.subconstruct-title {
    font-style: italic;
    margin-top: 5px;
    padding: 2.5px 0;
}
</style>
