import { computed, ComputedRef, defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import LosTeamVue from '../fearless/reports/LosTeam.vue';
import PsiTeamVue from '../fearless/reports/PsiTeam.vue';
import IevpTeamVue from '../ievp/views/IevpTeam.vue';
import PitTeamVue from '../pit/views/PitTeam.vue';
import BaseTeamReport, { setup } from './BaseTeamReport';
import RjcTeamVue from '../rjc/views/RjcTeam.vue';
import EpsiTeamVue from '../fearless/reports/EpsiTeam.vue';

export default defineComponent({
    extends: BaseTeamReport,
    components: {
        LosTeam: LosTeamVue,
        PsiTeam: PsiTeamVue,
        IevpTeam: IevpTeamVue,
        PitTeam: PitTeamVue,
        RjcTeam: RjcTeamVue,
        EpsiTeam: EpsiTeamVue,
    },
    setup() {
        const { loadReport, loaded, error } = setup();
        const { params } = useRoute();
        const reportName = params.instrumentAlias + '_' + params.reportAlias;
        const exposed = computed(() => params.reportAlias.indexOf('anonymous') === -1);
        const component: ComputedRef<string> = computed(() => {
            let name = 'psi-team';
            switch (reportName) {
                case 'los_los':
                    name = 'los-team';
                    break;
                case 'ievp_research_ievp':
                case 'ievp_research_ievp_extended':
                case 'ievp_ievp':
                case 'ievp-compact_ievp':
                case 'ievp_core_ievp':
                    name = 'ievp-team';
                    break;
                case 'pit-test-correct_pit':
                case 'pit-test_pit':
                    name = 'pit-team';
                    break;
                case 'rjc-full_rjc':
                case 'rjc-team_rjc':
                case 'rjc-short_rjc':
                    name = 'rjc-team';
                    break;
                case 'psi_org_epsi':
                case 'epsi-combined_epsi_c':
                    name = 'epsi-team';
                    break;
            }
            return name;
        });

        onMounted(async () => {
            loadReport(
                params.knowledgeModelAlias.toString(),
                params.instrumentAlias.toString(),
                params.reportAlias.toString(),
                params.knowledgeModelAlias.toString(),
            );
        });

        return { loaded, error, component, exposed };
    },
});
