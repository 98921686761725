import { computed, ComputedRef, defineComponent, onMounted, Ref, ref } from 'vue';
import CoverPageVue from '@/report/fearless/components/CoverPage.vue';
import he from 'he';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import BaseReportComponent from '../../components/BaseReportComponent';
import ITeamReportResponse from '@/report/models/ITeamReportResponse';
import ISection from '@/report/models/ISection';
import ProjectExportData from '@/report/models/IProjectExportData';
import { useRoute } from 'vue-router';
import BarChartScore from '@/report/models/Charts/BarChartScore';
import { ReportService } from '@/generic/services/reportService';
import QuestionOption from '@/report/models/QuestionOption';
import QuestionAnswerScore from '@/report/models/Demographic/IQuestionAnswerScore';
import { CategoryType } from '@/report/models/CategoryType';
import TeamCompareQuestion from '@/report/components/partials/TeamCompareQuestions.vue';
import TeamCompare from '../partials/TeamCompare.vue';
import TeamTable from '../partials/TeamTable.vue';
import TeamHeatmap from '../partials/TeamHeatmap.vue';
import { GroupScoreSet } from '@/report/models/DashboardData';
import { PillarThresholdType } from '@/report/models/PillarThreshold';

export default defineComponent({
    extends: BaseReportComponent,
    components: {
        CoverPage: CoverPageVue,
        TeamCompareQuestion,
        TeamCompare,
        TeamTable,
        TeamHeatmap,
    },
    props: {
        type: String,
    },
    setup(props) {
        const comparing = ref(false);
        const store = useStore();
        const { t } = useI18n();
        const compareTo: Ref<string> = ref('');
        const projectData: Ref<ProjectExportData> = ref();
        const { params, query } = useRoute();
        const projectId: number = +params.projectId;
        const token: string = query.token.toString();
        const compareScoresData: Ref<QuestionAnswerScore> = ref({ question: '', scores: [] });

        const context: ComputedRef<string> = computed(() => he.decode(store.getters['report/report'].context ?? ''));
        const report: ComputedRef<ITeamReportResponse> = computed(() => store.getters['report/report']);
        const participantsCompletedInvited: ComputedRef<string> = computed(() =>
            t(`${props.type}.PARTICIPANTS_COMPLETED_VS_INVITED`, {
                invitedParticipants: report.value.invitedParticipants,
                completedParticipants: report.value.completedParticipants,
            }),
        );
        const categoryKeys: string[] = Object.keys(CategoryType).filter((key) => {
            return CategoryType[key] !== CategoryType.General;
        });

        const epsiQuestionScores: ComputedRef<GroupScoreSet[]> = computed(() => {
            if (params.reportAlias == 'epsi_c') {
                return report.value.questionScores.data.filter(
                    (x) =>
                        x.groupName != 'QUESTION_6' &&
                        x.groupName != 'QUESTION_7' &&
                        x.groupName != 'QUESTION_8' &&
                        x.groupName != 'QUESTION_9' &&
                        x.groupName != 'QUESTION_10' &&
                        x.groupName != 'QUESTION_11' &&
                        x.groupName != 'QUESTION_12',
                );
            } else {
                return report.value.questionScores.data.filter((x) => x.groupName != 'QUESTION_1' && x.groupName != 'QUESTION_5');
            }
        });

        const scores = (data: ITeamReportResponse, category: string): BarChartScore => {
            if (data.dateReport && data.dateReport.sections) {
                const section = data.dateReport.sections.find((sec: ISection) => sec.category == category);
                return new BarChartScore({
                    teamMedian: section.teamMedian,
                    positiveAverage: section.positiveAverage,
                    negativeAverage: section.negativeAverage,
                    tooltipScore: section.teamMedian,
                    participants: section.participants,
                    teamQuartile: section.teamQuartile,
                    standardDeviation: section.standardDeviation,
                });
            }
            return new BarChartScore();
        };

        const compareQuestions = async (question: string): Promise<void> => {
            comparing.value = true;
            if (!question) {
                compareTo.value = '';
            } else {
                const service = new ReportService();
                const response = await service.getQuestionAnswerScores(projectId, question, token);
                compareScoresData.value = response;
                compareTo.value = question;
            }
            comparing.value = false;
        };

        const compareGeneralScores = (
            category: string,
        ): {
            category: string;
            pillar: string;
            compareAnswer: string;
            positiveAverage: number;
            negativeAverage: number;
            teamMedian: number;
            groupSize: number;
        }[] => {
            const scores = [];
            report.value.table.entries.forEach((entry) => {
                const splittedLabel = entry.title.split(':');
                if (splittedLabel.length && splittedLabel[0] === compareTo.value && entry.sections) {
                    entry.sections.forEach((section) => {
                        let label = '';
                        if (entry.title) {
                            label = splittedLabel.length > 1 ? splittedLabel[1] : 'None';
                        }
                        if (section.category == category) {
                            scores.push({
                                category: section.category,
                                pillar: section.category,
                                compareAnswer: label,
                                positiveAverage: section.positiveAverage,
                                negativeAverage: section.negativeAverage,
                                teamMedian: section.median,
                                groupSize: entry.groupSize,
                            });
                        }
                    });
                }
            });

            return scores;
        };

        const compareScores = (
            question: string,
        ): { compareAnswer: string; groupSize: number; negativeAverage: number; positiveAverage: number; teamMedian: number }[] => {
            const answer = compareScoresData.value.scores.find((compare) => {
                return compare.answer === question;
            });
            return answer ? answer.sections : [];
        };

        const questionOptions: ComputedRef<QuestionOption[]> = computed(() => {
            if (!report.value.table || !report.value.table.entries) {
                return [];
            }

            const allEntries = report.value.table.entries.map((entry: any) => {
                return {
                    label: entry.title.split(':')[0],
                    question: entry.title.split(':')[0],
                };
            });

            const uniqueEntries = [];
            allEntries.forEach((entry) => {
                if (!uniqueEntries.find((x) => x.label === entry.label)) {
                    uniqueEntries.push(entry);
                }
            });

            return uniqueEntries;
        });

        const getProjectData = async (): Promise<void> => {
            const service = new ReportService();
            const response = await service.getProjectData(projectId, token);
            projectData.value = response;
        };

        const mapResult = (result: GroupScoreSet): { teamMedian: number; negativeAverage: number; positiveAverage: number } => {
            return {
                teamMedian: result.median,
                negativeAverage: result.negativeAverage,
                positiveAverage: result.positiveAverage,
            };
        };

        onMounted(async () => {
            await getProjectData();

            const lang = store.getters.language;
            lang.section = 'survey';
            lang.reportAlias = null;
            await store.dispatch('setLanguage', lang);
        });

        return {
            report,
            context,
            CategoryType,
            questionOptions,
            comparing,
            compareTo,
            projectData,
            participantsCompletedInvited,
            categoryKeys,
            epsiQuestionScores,
            compareGeneralScores,
            compareScores,
            compareQuestions,
            mapResult,
            scores,
        };
    },
});
